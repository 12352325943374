import { useEffect, useState, useContext } from 'react';
import { Link, graphql } from 'gatsby';
import _ from 'lodash';
import Layout from "../../../../src/components/layout";
import Toolbar from "../../../../src/components/Toolbar";
import FilmItem from "../../../../src/components/FilmItem";
import Image from "../../../../src/components/image";
import SEO from "../../../../src/components/seo";
import PageLoader from "../../../../src/components/PageLoader";
import { FilmsWrapper } from "../../../../src/components/styled";
import { MovieContext } from "../../../../src/store";
import { UPDATE_STREAMING_PROVIDERS } from "../../../../src/store/types";
import { streamingProviders } from "../../../../src/helper/constants";
import React from 'react';
export default {
  useEffect,
  useState,
  useContext,
  Link,
  graphql,
  _,
  Layout,
  Toolbar,
  FilmItem,
  Image,
  SEO,
  PageLoader,
  FilmsWrapper,
  MovieContext,
  UPDATE_STREAMING_PROVIDERS,
  streamingProviders,
  React
};