import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { useEffect, useState, useContext } from 'react';
import { Link, graphql } from 'gatsby';
import _ from 'lodash';
import Layout from '../components/layout';
import Toolbar from '../components/Toolbar';
import FilmItem from '../components/FilmItem';
import Image from '../components/image';
import SEO from '../components/seo';
import PageLoader from '../components/PageLoader';
import { FilmsWrapper } from './../components/styled';
import { MovieContext } from './../store';
import { UPDATE_STREAMING_PROVIDERS } from './../store/types';
import { streamingProviders } from './../helper/constants';
export const query = graphql`
	query allFilmsQuery {
		allFilmsJson {
			edges {
				node {
					movieId
					jwId
					movieName
					movieYear
					moviePoster
					movieRating
					imdbRating
					movieDescription
					dayWatched
					dateWatched
				}
			}
		}
	}
`;

async function fetchStreamingProviders() {
  try {
    const response = await fetch('https://apis.justwatch.com/content/providers/locale/en_IN');
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
}

function sortFilms({
  order = "asc",
  base = null,
  films
}) {
  if (base) {
    switch (base) {
      case "customRating":
        const sortedFilmsByCustomRating = _.orderBy(films, ['movieRating'], [order]);

        return sortedFilmsByCustomRating;

      case "imdbRating":
        const sortedFilmsByImdbRating = _.orderBy(films, ['imdbRating'], [order]);

        return sortedFilmsByImdbRating;

      case "movieYear":
        const sortedFilmsByMovieYear = _.orderBy(films, ['movieYear'], [order]);

        return sortedFilmsByMovieYear;

      case "dateWatched":
        const stringifiedFilms = JSON.parse(JSON.stringify(films));
        const timestampedFilms = stringifiedFilms.map(film => {
          if (typeof film.dateWatched === "number") return film;
          let unformattedDate = film.dateWatched.split('.');
          const month = unformattedDate[0];
          unformattedDate[0] = unformattedDate[1];
          unformattedDate[1] = month;
          const timestamp = new Date(unformattedDate.join('.')).getTime() / 1000;
          film.dateWatched = timestamp;
          return film;
        });

        const sortedFilmsByWatchedDate = _.orderBy(timestampedFilms, ['dateWatched'], [order]);

        return sortedFilmsByWatchedDate;

      default:
        const defaultSortedFilms = _.orderBy(films, ['movieId'], ["asc"]);

        return defaultSortedFilms;
    }
  } else {
    const sortedFilms = _.orderBy(films, ['movieId'], [order]);

    return sortedFilms;
  }
}

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};

const MDXLayout = function Index({
  data: {
    allFilmsJson: {
      edges: filmEdges
    }
  }
}) {
  const [loading, setLoading] = useState(true);
  const [films, setFilms] = useState([]);
  const [filmsOrder, setFilmsOrder] = useState("asc");
  const [lastSortingOperation, setLastSortingOperation] = useState(null);
  const {
    dispatch
  } = useContext(MovieContext);
  useEffect(() => {
    (async () => {
      const config = window.localStorage.getItem("config");
      let loadTime = 3000;

      if (config) {
        const parsedConfig = JSON.parse(config);

        if (parsedConfig.loaded) {
          loadTime = 300;
        }
      }

      setTimeout(() => {
        const parsedFilms = filmEdges.map(film => film["node"]);
        setFilms(parsedFilms);
        setLoading(false);
      }, loadTime);
      window.localStorage.setItem("config", JSON.stringify({
        loaded: true
      }));
      const stramingProviders = await fetchStreamingProviders();
      const parseStreamingProviders = {};
      stramingProviders.forEach(provdr => parseStreamingProviders[provdr.id] = {
        name: provdr.clear_name,
        key: provdr.technical_name,
        logo: streamingProviders[provdr.technical_name] || ""
      });
      await dispatch({
        type: UPDATE_STREAMING_PROVIDERS,
        payload: parseStreamingProviders
      });
    })();
  }, []);
  return loading ? <PageLoader /> : <Layout>
			<SEO title="Home" keywords={['gatsby', 'application', 'react']} />
			<Toolbar sortingOrder={filmsOrder} onChangeOfOrder={order => {
      setFilmsOrder(order);
      const orderedFilms = sortFilms({
        order,
        base: lastSortingOperation,
        films
      });
      setFilms(orderedFilms);
    }} onSorting={sel => {
      setLastSortingOperation(sel);
      const sortedFilms = sortFilms({
        order: filmsOrder,
        base: sel,
        films
      });
      setFilms(sortedFilms);
    }} />
			<FilmsWrapper>
				{films.map(filmNode => <FilmItem key={filmNode.movieId} movieId={filmNode.movieId} moviePoster={filmNode.moviePoster} movieName={filmNode.movieName} movieYear={filmNode.movieYear} movieRating={filmNode.movieRating} dayWatched={filmNode.dayWatched} dateWatched={filmNode.dateWatched} movieDescription={filmNode.movieDescription} jwId={filmNode.jwId} />)}
			</FilmsWrapper>
		</Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      